import React from "react"
import { useStaticQuery } from "gatsby"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"
import sparks from "../videos/sparks.mp4"
import Img from "gatsby-image"

const useStyles = makeStyles(
  theme =>
    createStyles({
      bannerContainer: { position: "relative" },
      logoContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 50,
        "& h1": {
          color: "white",
          fontSize: "3rem",
          [theme.breakpoints.down("sm")]: {
            paddingTop: 10
          }
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          textAlign: "center"
        }
      },
      videoContainer: {
        top: "0%",
        left: "0%",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "absolute",
        zIndex: "-1"
      },
      "& video": {
        position: "absolute",
        zIndex: "0",
        width: "100%",
        minWidth: 1700,
        top: 0
      }
    }),
  { name: "MuiPageBanner", index: 1 }
)

const PageBanner = ({ title }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      kumiteIcon: file(relativePath: { eq: "kumite-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container maxWidth="lg" className={classes.bannerContainer}>
      <header className={classes.logoContainer}>
        <div style={{ height: 200, width: 200 }}>
          <Img fluid={data.kumiteIcon.childImageSharp.fluid} alt="logo" />
        </div>
        <div>
          <h1>{title}</h1>
        </div>
      </header>
      <div className={classes.videoContainer}>
        <video id="videoBG" autoPlay muted loop>
          <source src={sparks} type="video/mp4" />
        </video>
      </div>
    </Container>
  )
}

export default PageBanner
