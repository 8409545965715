import React from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { Grid, Container, IconButton } from "@material-ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import moment from "moment"
import SectionTitle from "../SectionTitle"
import Img from "gatsby-image"
import FooterTwitterPosts from "./FooterTwitterPosts"

const useStyles = makeStyles(
  theme =>
    createStyles({
      personalLink: {
        color: "#e12425",
        "& :visited": {
          color: "#e12425"
        },
        "& :hover": {
          color: "white"
        }
      }
    }),
  { name: "MuiFooter", index: 1 }
)

const Footer = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          image
          title
          keywords
          url
        }
      }
      gameGalaxy: file(relativePath: { eq: "game-galaxy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kumite: file(relativePath: { eq: "kumite.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const meta = data.site.siteMetadata
  return (
    <footer style={{ paddingTop: 60 }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <section
              className="footer_site_description"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div style={{ width: "100%" }}>
                <Img
                  fluid={data.kumite.childImageSharp.fluid}
                  alt={meta.title}
                />
              </div>
              <h2>{meta.title}</h2>
              <p>{meta.description}</p>
            </section>
          </Grid>
          <Grid item xs={12} md={4}>
            <section className="footer_recent_news">
              <SectionTitle title="Recent News" navigate="/news" />
              <FooterTwitterPosts />
            </section>
          </Grid>
          <Grid item xs={12} md={4}>
            <section className="footer_recent_community">
              <SectionTitle title="Socials" />
              <IconButton
                aria-label="navigate to facebook page"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 20,
                  cursor: "pointer",
                  color: "#d4d4d4"
                }}
                onClick={() =>
                  navigate(`https://www.facebook.com/KumiteGaming`)
                }
              >
                <FacebookIcon />
                <span style={{ paddingLeft: 10 }}>
                  Like our page on Facebook
                </span>
              </IconButton>
              <IconButton
                aria-label="navigate to twitter page"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 20,
                  cursor: "pointer",
                  color: "#d4d4d4"
                }}
                onClick={() => navigate(`https://twitter.com/KumiteGaming`)}
              >
                <TwitterIcon />
                <span style={{ paddingLeft: 10 }}>Follow us on Twitter</span>
              </IconButton>
            </section>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          background: "#282828",
          width: "100%",
          height: "100%",
          padding: "30px 0",
          margin: "30px 0 0"
        }}
      >
        <p style={{ textAlign: "center", padding: "0 10px" }}>
          &copy; Kumite Gaming {moment().format("yyyy")}. Powered by GatsbyJS,
          Material-UI, &amp; Smash.gg
        </p>
        <p style={{ textAlign: "center", padding: "0 10px" }}>
          Site Designed and Developed by{" "}
          <a
            href="https://www.linkedin.com/in/jevonthomas/"
            className={classes.personalLink}
          >
            Jevon Thomas
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
