import React from "react"
import TwitterFeed from "../components/twitter/TwitterFeed"
import { Container } from "@material-ui/core"
import Footer from "../components/footer/Footer"
import Nav from "../components/navigation/Nav"
import PageBanner from "../components/PageBanner"
import SEO from "../components/SEO"
const NewsPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Home"
        description="Welcome to Kumite Gaming. We host two of the largest esports tournaments in the fighting game community: Kumite in Tennessee and Kumite in Texas."
        keywords="KIT, KITX, summer bash, game galaxy, tennessee, texas, fgc, tournament, fighing games, esports"
      />
      <header>
        <Nav pathname={location.pathname} />
        <PageBanner title="News" />
      </header>
      <main>
        <Container maxWidth="lg" style={{ background: "#181818" }}>
          <TwitterFeed />
        </Container>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default NewsPage
