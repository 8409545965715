import React from "react"
import { graphql, StaticQuery } from "gatsby"
import moment from "moment"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const defaultImage = require("../../images/kumite-icon.jpg")
const twitterLogo = require("../../images/twitter-logo.png")

const useStyles = makeStyles(
  theme =>
    createStyles({
      cards: {
        paddingTop: 30,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {}
      },
      card: {
        display: "grid",
        marginBottom: 30,
        gridTemplateRows: "2fr 1fr auto auto",
        gridGap: 10,
        "& > a": {
          minHeight: 200
        },
        [theme.breakpoints.up("xs")]: {
          gridTemplateRows: "3fr 1fr auto auto"
          // flex: "0 1 calc(100% - 1em)"
        },
        [theme.breakpoints.up("sm")]: {
          gridTemplateRows: "2.5fr 1fr auto auto",
          flex: "0 1 calc(50% - 1em)"
        },
        [theme.breakpoints.up("md")]: {
          flex: "0 1 calc(33% - 1em)"
        }
      },
      twitterLink: {
        color: "#e12425",
        "& :visited": {
          color: "#e12425"
        },
        "& :hover": {
          color: "white"
        }
      }
    }),
  { name: "MuiTwitter", index: 1 }
)

const TwitterFeed = () => {
  const classes = useStyles()

  return (
    <StaticQuery
      query={graphql`
        query {
          allTwitterStatusesUserTimelineKumite {
            edges {
              node {
                id_str
                full_text
                created_at
                user {
                  screen_name
                  name
                  profile_image_url_https
                  url
                }
                entities {
                  media {
                    id
                    type
                    media_url_https
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <section className={classes.cards}>
            {data.allTwitterStatusesUserTimelineKumite.edges
              .map(({ node }) => node)
              .map(({ full_text, id_str, user, entities, created_at }) => (
                <article key={id_str} className={classes.card}>
                  <a
                    href={
                      "https://www.twitter.com/" +
                      user.screen_name +
                      "/status/" +
                      id_str
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{
                        backgroundImage: `url(${
                          entities.media !== null
                            ? entities.media[0].media_url_https
                            : defaultImage
                        })`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "100%",
                        width: "100%"
                      }}
                    ></div>
                  </a>
                  <p>{full_text}</p>
                  <img
                    style={{ margin: "0 auto" }}
                    src={twitterLogo}
                    alt="twitter"
                  />
                  <div style={{ textAlign: "center" }}>
                    <p>
                      <a
                        className={classes.twitterLink}
                        href="https://twitter.com/KumiteGaming"
                      >
                        @{user.screen_name}
                      </a>
                    </p>
                    <p>{moment(created_at).fromNow()}</p>
                  </div>
                </article>
              ))}
          </section>
        </>
      )}
    />
  )
}

export default TwitterFeed
